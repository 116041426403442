import React from "react"
import { Link } from "gatsby"

type CallToActionProps = {
  title?: string
  tagLine?: string
  img?: {
    alt: string
    src: string
    width?: number
    height?: number
  }
  link?: {
    to: string
    text: string
  }
}

const CallToAction: React.FC<CallToActionProps> = ({ title, tagLine, img, link }) => {
  return (
    <div className="px-6 md:px-12 xl:px-20">
      <div className="max-w-[1440px] mx-auto px-6 py-[120px] bg-sky-blue dark:bg-midnight-sky rounded-md flex flex-col items-center text-center">
        {title && (
          <h2 className="font-rocgrotesk text-midnight-sky dark:text-white leading-none first:mt-0 mt-9 mb-[18px] break-words font-bold text-[24px] lg:text-[48px] lg:leading-[72px]">
            {title}
          </h2>
        )}

        {tagLine && <div className="font-sans text-[18px] leading-[27px]">{tagLine}</div>}

        {link && (
          <Link
            to={link.to}
            className="mt-9 px-8 py-2 rounded-md font-rocgrotesk font-medium text-center text-white bg-tangerine hover:bg-dark-tangerine text-[18px] leading-[24px] h-12 flex items-center justify-center w-full md:w-auto"
          >
            {link.text}
          </Link>
        )}
      </div>
    </div>
  )
}

export default CallToAction
