import React from "react"

import CirclesGraphic from "../../images/graphic_circles_580x1284.png"

const WelcomeHero: React.FC = () => {
  return (
    <div className="bg-midnight-sky text-white font-rocgrotesk px-6 md:px-12 xl:px-20 relative">
      <img src={CirclesGraphic} alt="Circles" className="absolute right-0 bottom-0 h-full hidden xl:block" />
      <div className="max-w-[1440px] mx-auto flex flex-row justify-center items-center gap-x-4 lg:gap-x-8 xl:gap-x-12">
        <div className="flex-1 py-[120px] text-center">
          <div className="max-w-[696px] mx-auto">
            <h1 className="font-bold text-3xl xl:text-[64px] leading-relaxed xl:leading-[72px]">Seamless Payments, Simplified</h1>

            <span className="max-w-[440px] mt-[18px] inline-block text-[18px] font-sans font-light">
              Guides, resources, and references to help power up your payments with Payroc!
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WelcomeHero
