import React from "react"
import { PageProps } from "gatsby"

import PageMetadata from "../components/page/page-metadata"
import WelcomeHero from "../components/landing/welcome-hero"
import CallToAction from "../components/landing/call-to-action"
import Cards from "../components/landing/cards"
import { useSiteMetadata } from "../hooks/site-metadata"

const LandingPage: React.FC<PageProps> = () => {
  const { landingPageCards } = useSiteMetadata()
  return (
    <div className="flex flex-col pb-28">
      <PageMetadata title="Welcome" />
      <WelcomeHero />
      <Cards cards={landingPageCards} />
      <CallToAction
        title="Begin your integration journey"
        tagLine="Take a look at one of our guides to get started."
        link={{
          to: "/guides/getting-started",
          text: "Get started",
        }}
      />
    </div>
  )
}

export default LandingPage
