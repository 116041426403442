import React from "react"
import { LandingPageCard } from "../../hooks/site-metadata"
import { ContentCard } from "../common/content-section-grid-list"

export type EllipseProps = {
  cards: LandingPageCard[]
}

const Cards: React.FC<EllipseProps> = ({ cards }): React.ReactElement => (
  <div className="mx-6 md:mx-12 xl:mx-20">
    <div className="max-w-[1440px] mx-auto flex justify-center">
      <div className="flex-col justify-start">
        <div className="py-8 md:py-[7.5rem] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 xl:gap-12">
          {cards.map((props) => (
            <ContentCard key={props.title} {...props} />
          ))}
        </div>
      </div>
    </div>
  </div>
)

export default Cards
